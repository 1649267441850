.public-witnessing {
  font-family: 'Poppins', sans-serif;
}

.material-symbols-outlined {
  vertical-align: middle;
}

/* all buttons! */
.react-calendar button {
  color: black;
  background-color: white;
  border: 0 solid black;
}

/* hovering over current month days */
.react-calendar__month-view__days__day:hover abbr {
  background-color: rgb(186, 226, 254);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  width: 30px;
  height: 30px;
}

/* hovering over neighbor month days */
button.react-calendar__month-view__days__day--neighboringMonth:hover {
  background-color: white !important;
  cursor: initial;
}

/* navigation buttons: < month > */
.react-calendar__navigation {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 30px;
}

/* month button */
button.react-calendar__navigation__label {
  background: none;
  border: none;
  /* color: rgb(249, 161, 167); */
  color: rgb(119, 201, 204);
  font-size: 1.75rem;
  font-weight: bold;
}

/* navigation arrows only */
button.react-calendar__navigation__arrow {
  border: 1px solid gray;
  border-radius: 5px;
  width: 3rem;
  aspect-ratio: 1;
  padding: 0;
}

/* days labels */
.react-calendar__month-view__weekdays {
  text-align: center;
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
}
.react-calendar__month-view__weekdays abbr {
  cursor: default;
  text-decoration: none;
}

/* today */
button.react-calendar__tile--now abbr {
  /* background-color: rgb(249, 161, 167); */
  background-color: rgb(119, 201, 204);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  width: 30px;
  height: 30px;
}

/* calendar days */
.react-calendar__tile {
  aspect-ratio: 1;
  font-size: 1.1rem;
}

/* corners */
.react-calendar__tile:first-child {
  border-top-left-radius: 5px;
}
.react-calendar__tile:last-child {
  border-bottom-right-radius: 5px;
}
.react-calendar__tile:nth-child(7) {
  border-top-right-radius: 5px;
}
.react-calendar__month-view__days__day--weekend:nth-last-child() {
  border-bottom-left-radius: 5px;
}

.legend-today {
  display: inline-block;
  background-color: rgb(119, 201, 204);
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-right: 1rem;
  text-align: center;
}

.pdf-links {
  display: block;
  margin-bottom: 0.75rem;
}

.external-link {
  font-size: 1rem;
  text-decoration: underline;
  border-left: 1px solid;
  padding-left: 2px;
  vertical-align: baseline;
}
